<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="admin-article"
    :row-color-function="rowColorFunction"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "tags",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/article",
      deleteUrl: "api/admin/article/delete/",
      rowColorFunction: function (item) {
        return !item.active;
      },

      editPage: "/admin-dashboard/article/",
      title: "Статьи",
      columns: [
        {
          name: "Article Id",
          value: "id",
        },
        {
          name: "Title",
          value: "title",
          processor: (item) => {
            return this.$getLocalizedValue(item, this.$i18n.locale);
          },
        },
      ],
    };
  },

  methods: {},
});
</script>
